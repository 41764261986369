.projects{
    display: flex;
    flex-wrap: wrap;
    justify-content:center ;
}
.projects__card{
    width: 18rem;
    padding: 15px 10px;
    /* border: 0.5px solid grey; */
    margin: 2rem;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 0.5px grey;

}